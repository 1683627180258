<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <section class="checkout-section section-gap-30">
                <div class="checkout-card details__card gutter-10">
                    <div class="details__card__body form-box">
                        <div class="col-md-8 offset-md-2">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 977.72 357.146">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect width="977.72" height="357.146" fill="none" />
                                    </clipPath>
                                    <linearGradient id="linear-gradient" x1="0.317" y1="0.238" x2="0.919" y2="0.881"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0.152" stop-color="#231e25" />
                                        <stop offset="1" stop-color="#343336" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-2" x1="0.043" y1="0.236" x2="0.855" y2="0.695"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0.152" stop-color="#02064f" />
                                        <stop offset="1" stop-color="#0b137d" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-3" x1="0.632" y1="0.503" x2="0.052" y2="0.692"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0.152" stop-color="#231e25" />
                                        <stop offset="1" stop-color="#49484b" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-4" x1="0.001" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#eb006d" />
                                        <stop offset="0.041" stop-color="#597dfc" />
                                        <stop offset="1" stop-color="#2fc1e1" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-5" x1="-0.001" y1="0.5" x2="0.999" y2="0.5"
                                                    xlink:href="#linear-gradient-4" />
                                    <linearGradient id="linear-gradient-6" x1="-0.444" y1="1.104" x2="0.567" y2="0.452"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0.366" stop-color="#cdcfd1" />
                                        <stop offset="0.586" stop-color="#e4e5e6" />
                                        <stop offset="0.832" stop-color="#f8f8f8" />
                                        <stop offset="1" stop-color="#fff" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-7" x1="0" y1="0.501" x2="1" y2="0.501" xlink:href="#linear-gradient-4" />
                                    <linearGradient id="linear-gradient-8" x1="-0.002" y1="0.501" x2="0.997" y2="0.501"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#eb006d" />
                                        <stop offset="0.041" stop-color="#597dfc" />
                                        <stop offset="1" stop-color="#29b8f8" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-9" x1="-0.004" y1="0.502" x2="0.999" y2="0.502"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#2fc1e1" />
                                        <stop offset="0.959" stop-color="#c6d3fd" />
                                        <stop offset="1" stop-color="#597dfc" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-10" x1="-0.006" y1="0.5" x2="0.999" y2="0.5"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#49484b" />
                                        <stop offset="0.848" stop-color="#231e25" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-11" x1="0.001" y1="0.5" x2="1" y2="0.5" xlink:href="#linear-gradient-4" />
                                    <linearGradient id="linear-gradient-12" x1="-0.003" y1="0.5" x2="0.998" y2="0.5"
                                                    xlink:href="#linear-gradient-4" />
                                    <linearGradient id="linear-gradient-13" x1="0.076" y1="1.055" x2="0.919" y2="0.403"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0.057" stop-color="#dbdddf" />
                                        <stop offset="1" stop-color="#dbdddf" stop-opacity="0" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-14" x1="-0.034" y1="1.002" x2="1.017" y2="0.538"
                                                    xlink:href="#linear-gradient-13" />
                                    <linearGradient id="linear-gradient-15" x1="0.192" y1="0.216" x2="0.923" y2="1.077"
                                                    xlink:href="#linear-gradient" />
                                    <linearGradient id="linear-gradient-16" x1="0.393" y1="0.891" x2="0.371" y2="1.828"
                                                    xlink:href="#linear-gradient-2" />
                                    <linearGradient id="linear-gradient-17" x1="0.001" y1="0.5" x2="0.999" y2="0.5"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0.152" stop-color="#09004d" />
                                        <stop offset="1" stop-color="#040730" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-18" x1="2.472" y1="7.129" x2="0.73" y2="7.976"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0.218" stop-color="#ffd6d6" />
                                        <stop offset="1" stop-color="#ffaca8" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-19" x1="4.601" y1="32.254" x2="3.309" y2="33.057"
                                                    xlink:href="#linear-gradient-18" />
                                    <linearGradient id="linear-gradient-20" x1="4.516" y1="32.308" x2="3.228" y2="33.11"
                                                    xlink:href="#linear-gradient-18" />
                                    <linearGradient id="linear-gradient-21" x1="4.447" y1="32.361" x2="3.16" y2="33.163"
                                                    xlink:href="#linear-gradient-18" />
                                    <linearGradient id="linear-gradient-22" x1="4.7" y1="34.176" x2="3.329" y2="35.025"
                                                    xlink:href="#linear-gradient-18" />
                                    <linearGradient id="linear-gradient-23" x1="2.195" y1="10.883" x2="1.022" y2="11.326"
                                                    xlink:href="#linear-gradient-18" />
                                    <linearGradient id="linear-gradient-24" x1="0.205" y1="3.68" x2="1.278" y2="3.68"
                                                    xlink:href="#linear-gradient-2" />
                                    <linearGradient id="linear-gradient-25" x1="1.102" y1="3.895" x2="0.856" y2="2.93"
                                                    xlink:href="#linear-gradient-2" />
                                    <linearGradient id="linear-gradient-26" x1="0.647" y1="2.824" x2="0.474" y2="2"
                                                    xlink:href="#linear-gradient-2" />
                                    <linearGradient id="linear-gradient-27" x1="0.128" y1="0.873" x2="1.004" y2="-0.049"
                                                    xlink:href="#linear-gradient-3" />
                                    <linearGradient id="linear-gradient-28" x1="0.217" y1="0.876" x2="0.581" y2="0.36"
                                                    xlink:href="#linear-gradient-3" />
                                    <linearGradient id="linear-gradient-29" x1="-0.36" y1="1.615" x2="0.889" y2="-0.003"
                                                    xlink:href="#linear-gradient-3" />
                                    <linearGradient id="linear-gradient-30" x1="0.182" y1="0.991" x2="1.357" y2="-0.75"
                                                    xlink:href="#linear-gradient-3" />
                                    <linearGradient id="linear-gradient-31" x1="0.619" y1="0.278" x2="-0.219" y2="1.614"
                                                    xlink:href="#linear-gradient-3" />
                                    <linearGradient id="linear-gradient-32" x1="-0.005" y1="1.152" x2="0.899" y2="0.133"
                                                    xlink:href="#linear-gradient-18" />
                                    <linearGradient id="linear-gradient-33" x1="1.122" y1="-0.071" x2="-0.384" y2="1.202"
                                                    xlink:href="#linear-gradient-2" />
                                    <linearGradient id="linear-gradient-34" x1="2.558" y1="22.146" x2="1.651" y2="20.813"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0.226" stop-color="#abdcf4" />
                                        <stop offset="0.749" stop-color="#dbeffb" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-35" x1="0.656" y1="0.864" x2="0.374" y2="0.021"
                                                    xlink:href="#linear-gradient-18" />
                                    <linearGradient id="linear-gradient-36" x1="1.152" y1="0.797" x2="-0.057" y2="-0.118"
                                                    xlink:href="#linear-gradient-18" />
                                    <linearGradient id="linear-gradient-37" x1="0.656" y1="-0.198" x2="0.339" y2="0.68"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0.071" stop-color="#7a3923" />
                                        <stop offset="1" stop-color="#3e190c" />
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-38" x1="2.146" y1="12.65" x2="2.146" y2="13.198"
                                                    xlink:href="#linear-gradient-34" />
                                    <linearGradient id="linear-gradient-39" x1="0.507" y1="10.938" x2="1.536" y2="13.033"
                                                    xlink:href="#linear-gradient-34" />
                                </defs>
                                <g id="Repeat_Grid_1" data-name="Repeat Grid 1" clip-path="url(#clip-path)">
                                    <g transform="translate(-130.08 -149.545)">
                                        <g id="Group_623" data-name="Group 623" transform="translate(-10134.816 20897.547)">
                                            <g id="roket" transform="translate(10602 -20748.002)">
                                                <g id="Group_604" data-name="Group 604" transform="translate(16.789 2.622)">
                                                    <g id="Group_554" data-name="Group 554" transform="translate(90.345 184.119)">
                                                        <g id="Group_553" data-name="Group 553">
                                                            <path id="Path_684" data-name="Path 684"
                                                                  d="M167.286,111.925a1.512,1.512,0,0,0,0-3.025c-1.815,0-1.815,3.025,0,3.025Z"
                                                                  transform="translate(-165.925 -108.9)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_556" data-name="Group 556" transform="translate(135.165 256.306)">
                                                        <g id="Group_555" data-name="Group 555">
                                                            <path id="Path_685" data-name="Path 685"
                                                                  d="M189.662,147.73a1.512,1.512,0,0,0,0-3.025c-2.017-.2-2.017,3.025,0,3.025Z"
                                                                  transform="translate(-188.15 -144.696)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_558" data-name="Group 558" transform="translate(30.955 179.884)">
                                                        <g id="Group_557" data-name="Group 557">
                                                            <path id="Path_686" data-name="Path 686"
                                                                  d="M139.349,112.85c4.033,0,4.033-6.05,0-6.05-3.832,0-3.832,6.05,0,6.05Z"
                                                                  transform="translate(-136.475 -106.8)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_560" data-name="Group 560" transform="translate(66.952 82.481)">
                                                        <g id="Group_559" data-name="Group 559">
                                                            <path id="Path_687" data-name="Path 687"
                                                                  d="M155.686,61.525a1.512,1.512,0,0,0,0-3.025c-1.815,0-1.815,3.025,0,3.025Z"
                                                                  transform="translate(-154.325 -58.5)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_562" data-name="Group 562" transform="translate(245.223 298.059)">
                                                        <g id="Group_561" data-name="Group 561">
                                                            <path id="Path_688" data-name="Path 688"
                                                                  d="M244.086,168.425a1.512,1.512,0,0,0,0-3.025c-1.815,0-1.815,3.025,0,3.025Z"
                                                                  transform="translate(-242.725 -165.4)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_564" data-name="Group 564" transform="translate(248.299 249.257)">
                                                        <g id="Group_563" data-name="Group 563">
                                                            <path id="Path_689" data-name="Path 689"
                                                                  d="M245.762,144.225a1.512,1.512,0,0,0,0-3.025,1.512,1.512,0,1,0,0,3.025Z"
                                                                  transform="translate(-244.25 -141.2)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_566" data-name="Group 566" transform="translate(124.477 322.662)">
                                                        <g id="Group_565" data-name="Group 565">
                                                            <path id="Path_690" data-name="Path 690"
                                                                  d="M184.363,180.625a1.512,1.512,0,1,0,0-3.025,1.512,1.512,0,0,0,0,3.025Z"
                                                                  transform="translate(-182.85 -177.6)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_568" data-name="Group 568" transform="translate(65.793 312.579)">
                                                        <g id="Group_567" data-name="Group 567">
                                                            <path id="Path_691" data-name="Path 691"
                                                                  d="M155.263,175.625a1.512,1.512,0,0,0,0-3.025,1.512,1.512,0,1,0,0,3.025Z"
                                                                  transform="translate(-153.75 -172.6)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_570" data-name="Group 570" transform="translate(0 265.39)">
                                                        <g id="Group_569" data-name="Group 569">
                                                            <path id="Path_692" data-name="Path 692"
                                                                  d="M123.7,154.645c3.428,0,3.428-5.445,0-5.445-3.428.2-3.428,5.445,0,5.445Z"
                                                                  transform="translate(-121.125 -149.2)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_572" data-name="Group 572" transform="translate(19.813 303.899)">
                                                        <g id="Group_571" data-name="Group 571">
                                                            <path id="Path_693" data-name="Path 693"
                                                                  d="M132.462,171.33a1.512,1.512,0,0,0,0-3.025c-2.017-.2-2.017,3.025,0,3.025Z"
                                                                  transform="translate(-130.95 -168.295)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_574" data-name="Group 574" transform="translate(173.229 296.043)">
                                                        <g id="Group_573" data-name="Group 573">
                                                            <path id="Path_694" data-name="Path 694"
                                                                  d="M208.386,167.425a1.512,1.512,0,0,0,0-3.025c-1.815,0-1.815,3.025,0,3.025Z"
                                                                  transform="translate(-207.025 -164.4)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_576" data-name="Group 576" transform="translate(152.458 30.653)">
                                                        <g id="Group_575" data-name="Group 575">
                                                            <path id="Path_695" data-name="Path 695"
                                                                  d="M199.3,38.245c3.428,0,3.428-5.445,0-5.445s-3.428,5.445,0,5.445Z"
                                                                  transform="translate(-196.725 -32.8)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_578" data-name="Group 578" transform="translate(48.853)">
                                                        <g id="Group_577" data-name="Group 577">
                                                            <path id="Path_696" data-name="Path 696"
                                                                  d="M146.862,20.625a1.512,1.512,0,1,0,0-3.025,1.512,1.512,0,0,0,0,3.025Z"
                                                                  transform="translate(-145.35 -17.6)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_580" data-name="Group 580" transform="translate(13.562 32.67)">
                                                        <g id="Group_579" data-name="Group 579">
                                                            <path id="Path_697" data-name="Path 697"
                                                                  d="M129.363,36.825a1.512,1.512,0,0,0,0-3.025,1.512,1.512,0,1,0,0,3.025Z"
                                                                  transform="translate(-127.85 -33.8)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_582" data-name="Group 582" transform="translate(203.529 320.242)">
                                                        <g id="Group_581" data-name="Group 581">
                                                            <path id="Path_698" data-name="Path 698"
                                                                  d="M223.562,179.425a1.512,1.512,0,0,0,0-3.025,1.512,1.512,0,1,0,0,3.025Z"
                                                                  transform="translate(-222.05 -176.4)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_584" data-name="Group 584" transform="translate(210.058 277.893)">
                                                        <g id="Group_583" data-name="Group 583">
                                                            <path id="Path_699" data-name="Path 699"
                                                                  d="M227.935,160.845c3.428,0,3.428-5.445,0-5.445-3.428.2-3.63,5.445,0,5.445Z"
                                                                  transform="translate(-225.287 -155.4)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_586" data-name="Group 586" transform="translate(80.791 346.853)">
                                                        <g id="Group_585" data-name="Group 585">
                                                            <path id="Path_700" data-name="Path 700"
                                                                  d="M162.625,192.63a1.512,1.512,0,0,0,0-3.025c-1.815-.2-2.017,3.025,0,3.025Z"
                                                                  transform="translate(-161.187 -189.596)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_588" data-name="Group 588" transform="translate(85.556 146.408)">
                                                        <g id="Group_587" data-name="Group 587">
                                                            <path id="Path_701" data-name="Path 701"
                                                                  d="M165.062,93.225a1.512,1.512,0,0,0,0-3.025,1.512,1.512,0,0,0,0,3.025Z"
                                                                  transform="translate(-163.55 -90.2)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_590" data-name="Group 590" transform="translate(112.73 108.495)">
                                                        <g id="Group_589" data-name="Group 589">
                                                            <path id="Path_702" data-name="Path 702"
                                                                  d="M178.386,74.425a1.512,1.512,0,1,0,0-3.025c-1.815,0-1.815,3.025,0,3.025Z"
                                                                  transform="translate(-177.025 -71.4)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_592" data-name="Group 592" transform="translate(175.095 209.126)">
                                                        <g id="Group_591" data-name="Group 591">
                                                            <path id="Path_703" data-name="Path 703"
                                                                  d="M209.462,124.325a1.512,1.512,0,0,0,0-3.025,1.512,1.512,0,0,0,0,3.025Z"
                                                                  transform="translate(-207.95 -121.3)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_594" data-name="Group 594" transform="translate(258.382 334.955)">
                                                        <g id="Group_593" data-name="Group 593">
                                                            <path id="Path_704" data-name="Path 704"
                                                                  d="M250.763,186.73a1.512,1.512,0,0,0,0-3.025c-2.017-.2-2.017,3.025,0,3.025Z"
                                                                  transform="translate(-249.25 -183.696)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_596" data-name="Group 596" transform="translate(295.286 322.662)">
                                                        <g id="Group_595" data-name="Group 595">
                                                            <path id="Path_705" data-name="Path 705"
                                                                  d="M269.062,180.625a1.512,1.512,0,1,0,0-3.025,1.512,1.512,0,0,0,0,3.025Z"
                                                                  transform="translate(-267.55 -177.6)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_598" data-name="Group 598" transform="translate(190.421 136.123)">
                                                        <g id="Group_597" data-name="Group 597">
                                                            <path id="Path_706" data-name="Path 706"
                                                                  d="M217.062,88.125a1.512,1.512,0,0,0,0-3.025,1.512,1.512,0,0,0,0,3.025Z"
                                                                  transform="translate(-215.55 -85.1)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_600" data-name="Group 600" transform="translate(17.545 98.614)">
                                                        <g id="Group_599" data-name="Group 599">
                                                            <path id="Path_707" data-name="Path 707"
                                                                  d="M131.186,69.525a1.512,1.512,0,1,0,0-3.025c-1.815,0-1.815,3.025,0,3.025Z"
                                                                  transform="translate(-129.825 -66.5)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_601" data-name="Group 601" transform="translate(1.361 0.807)">
                                                        <path id="Path_708" data-name="Path 708"
                                                              d="M167.712,109.9c0-.807-1.412-.807-1.412,0A.707.707,0,1,0,167.712,109.9Z"
                                                              transform="translate(-76.56 74.819)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_709" data-name="Path 709"
                                                              d="M189.912,145.7c0-.807-1.412-.807-1.412,0C188.7,146.512,189.912,146.512,189.912,145.7Z"
                                                              transform="translate(-53.99 111.215)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_710" data-name="Path 710"
                                                              d="M140.023,108.986a1.413,1.413,0,0,0-2.823,0A1.413,1.413,0,0,0,140.023,108.986Z"
                                                              transform="translate(-106.144 73.116)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_711" data-name="Path 711"
                                                              d="M156.112,59.5c0-.807-1.412-.807-1.412,0A.707.707,0,1,0,156.112,59.5Z"
                                                              transform="translate(-88.353 23.581)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_712" data-name="Path 712"
                                                              d="M244.512,166.405c0-.807-1.412-.807-1.412,0A.708.708,0,1,0,244.512,166.405Z"
                                                              transform="translate(1.518 132.259)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_713" data-name="Path 713"
                                                              d="M246.112,142.3c0-.807-1.412-.807-1.412,0S246.112,143.112,246.112,142.3Z"
                                                              transform="translate(3.145 107.758)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_714" data-name="Path 714"
                                                              d="M184.712,178.605c0-.807-1.412-.807-1.412,0S184.712,179.613,184.712,178.605Z"
                                                              transform="translate(-59.277 144.662)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_715" data-name="Path 715"
                                                              d="M155.612,173.7c0-.807-1.412-.807-1.412,0S155.612,174.512,155.612,173.7Z"
                                                              transform="translate(-88.861 139.681)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_716" data-name="Path 716"
                                                              d="M124.22,151.21a1.21,1.21,0,0,0-2.42,0A1.21,1.21,0,0,0,124.22,151.21Z"
                                                              transform="translate(-121.8 116.196)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_717" data-name="Path 717"
                                                              d="M132.712,169.3c0-.807-1.412-.807-1.412,0S132.712,170.112,132.712,169.3Z"
                                                              transform="translate(-112.142 135.208)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_718" data-name="Path 718"
                                                              d="M208.812,165.5c0-.807-1.412-.807-1.412,0S208.812,166.312,208.812,165.5Z"
                                                              transform="translate(-34.776 131.344)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_719" data-name="Path 719"
                                                              d="M184.535,44.753c0-2.42-3.832-2.622-3.832,0C180.5,47.173,184.535,47.173,184.535,44.753Z"
                                                              transform="translate(-61.924 7.276)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_720" data-name="Path 720"
                                                              d="M262.432,155.954c0-2.42-3.832-2.622-3.832,0C258.6,158.373,262.432,158.373,262.432,155.954Z"
                                                              transform="translate(17.276 120.326)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_721" data-name="Path 721"
                                                              d="M147.112,18.6c0-.807-1.412-.807-1.412,0C145.9,19.613,147.112,19.613,147.112,18.6Z"
                                                              transform="translate(-97.502 -18)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_722" data-name="Path 722"
                                                              d="M129.612,34.8c0-.807-1.412-.807-1.412,0C128.4,35.813,129.612,35.813,129.612,34.8Z"
                                                              transform="translate(-115.294 -1.53)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_723" data-name="Path 723"
                                                              d="M223.812,177.505c0-.807-1.412-.807-1.412,0C222.6,178.312,223.812,178.312,223.812,177.505Z"
                                                              transform="translate(-19.526 143.544)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_724" data-name="Path 724"
                                                              d="M228.42,157.41a1.21,1.21,0,0,0-2.42,0A1.21,1.21,0,1,0,228.42,157.41Z"
                                                              transform="translate(-15.866 122.5)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_725" data-name="Path 725"
                                                              d="M163.012,190.6c0-.807-1.412-.807-1.412,0S163.012,191.412,163.012,190.6Z"
                                                              transform="translate(-81.338 156.862)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_726" data-name="Path 726"
                                                              d="M165.312,91.205c0-.807-1.412-.807-1.412,0C164.1,92.213,165.312,92.213,165.312,91.205Z"
                                                              transform="translate(-78.999 55.808)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_727" data-name="Path 727"
                                                              d="M178.812,72.5c0-.807-1.412-.807-1.412,0S178.812,73.312,178.812,72.5Z"
                                                              transform="translate(-65.275 36.797)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_728" data-name="Path 728"
                                                              d="M209.712,122.3c0-.807-1.412-.807-1.412,0S209.712,123.112,209.712,122.3Z"
                                                              transform="translate(-33.861 87.426)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_729" data-name="Path 729"
                                                              d="M251.012,184.7c0-.807-1.412-.807-1.412,0C249.8,185.512,251.012,185.512,251.012,184.7Z"
                                                              transform="translate(8.127 150.864)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_730" data-name="Path 730"
                                                              d="M269.312,178.605c0-.807-1.412-.807-1.412,0S269.312,179.613,269.312,178.605Z"
                                                              transform="translate(26.731 144.662)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_731" data-name="Path 731"
                                                              d="M217.312,86.205c0-.807-1.412-.807-1.412,0C216.1,87.012,217.312,87.012,217.312,86.205Z"
                                                              transform="translate(-26.134 50.725)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_732" data-name="Path 732"
                                                              d="M131.612,67.6c0-.807-1.412-.807-1.412,0S131.612,68.412,131.612,67.6Z"
                                                              transform="translate(-113.26 31.815)" fill="rgba(198,211,253,0.5)" />
                                                        <path id="Path_733" data-name="Path 733"
                                                              d="M204.012,21.1c0-.807-1.412-.807-1.412,0A.708.708,0,1,0,204.012,21.1Z"
                                                              transform="translate(-39.655 -15.458)" fill="rgba(198,211,253,0.5)" />
                                                    </g>
                                                    <g id="Group_603" data-name="Group 603" transform="translate(163.6 5.042)">
                                                        <g id="Group_602" data-name="Group 602">
                                                            <path id="Path_734" data-name="Path 734"
                                                                  d="M203.762,23.125a1.512,1.512,0,0,0,0-3.025,1.512,1.512,0,0,0,0,3.025Z"
                                                                  transform="translate(-202.25 -20.1)" fill="rgba(198,211,253,0.5)" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <path id="Path_735" data-name="Path 735"
                                                      d="M112.8,373.447H458.049c.4-2.017.807-4.033,1.008-5.848,3.428-28.031-9.277-56.264-27.628-77.842s-42.349-37.51-65.742-53.239c-10.89-7.462-21.981-14.923-30.451-25.208s-13.915-23.595-11.7-36.7c2.622-15.931,15.73-32.266,7.663-46.181-13.511-23.191-67.154-12.1-42.349-55.256,5.243-9.075,12.5-16.738,16.94-26.015A50.654,50.654,0,0,0,309.826,16.3H112.8V373.447Z"
                                                      transform="translate(-112.8 -16.3)" fill="none" />
                                                <g id="Group_605" data-name="Group 605" transform="translate(161.573 177.255)">
                                                    <path id="Path_736" data-name="Path 736"
                                                          d="M223.333,162.057c.807,2.622,4.638,8.067,6.655,8.873s24.2-10.89,25.611-17.746c.807-3.832-15.528-2.42-22.385-1.613C229.383,151.974,222.526,159.436,223.333,162.057Z"
                                                          transform="translate(-162.067 -56.982)" fill="url(#linear-gradient)" />
                                                    <path id="Path_737" data-name="Path 737"
                                                          d="M205.585,104.2c-.2-.2,3.025,3.428,14.721,10.89,10.285,6.453,28.233,16.738,31.056,20.973s16.738,60.7,18.956,64.129c0,0-6.857,9.277-16.94,9.075,0,0-14.923-49.609-18.755-56.668s-34.081-17.141-37.106-20.166C194.9,129.009,185.217,106.624,205.585,104.2Z"
                                                          transform="translate(-192.92 -104.196)" fill="url(#linear-gradient-2)" />
                                                </g>
                                                <g id="Group_613" data-name="Group 613" transform="translate(0 173.135)">
                                                    <g id="Group_607" data-name="Group 607" transform="translate(0 93.264)">
                                                        <g id="Group_606" data-name="Group 606" transform="translate(72.913 1.613)">
                                                            <path id="Path_738" data-name="Path 738"
                                                                  d="M167.6,149.2a19.955,19.955,0,0,0-10.285,6.453l-8.067,8.873a1.252,1.252,0,0,0,.6,2.017l11.7,2.218a22.547,22.547,0,0,0,12.1-1.21Z"
                                                                  transform="translate(-148.956 -149.2)" fill="#ec671c" />
                                                            <path id="Path_739" data-name="Path 739"
                                                                  d="M166.428,150.4a20.321,20.321,0,0,0-9.277,5.647L150.9,163.1l9.277,1.613a19.2,19.2,0,0,0,10.688-1.21Z"
                                                                  transform="translate(-146.979 -147.98)" fill="#f3e600" />
                                                        </g>
                                                        <path id="Path_740" data-name="Path 740"
                                                              d="M112.8,195.993l98.21-26.418L203.952,148.4,112.8,186.515Z"
                                                              transform="translate(-112.8 -148.4)" fill="rgba(89,125,252,0.2)" />
                                                    </g>
                                                    <g id="Group_612" data-name="Group 612" transform="translate(71.792)">
                                                        <g id="Group_608" data-name="Group 608" transform="translate(18.393 71.686)">
                                                            <path id="Path_741" data-name="Path 741"
                                                                  d="M182.485,137.7l-24.8,12.1s-1.21,8.268,3.025,20.57,10.285,17.948,10.285,17.948l27.023-5.647Z"
                                                                  transform="translate(-157.521 -137.7)" fill="url(#linear-gradient-3)" />
                                                        </g>
                                                        <g id="Group_609" data-name="Group 609" transform="translate(0 37.403)">
                                                            <path id="Path_742" data-name="Path 742"
                                                                  d="M151.223,139.656l27.225,2.42,11.091,11.495,33.678-16.335L206.681,120.7,148.4,131.792Z"
                                                                  transform="translate(-148.4 -120.7)" fill="url(#linear-gradient-4)" />
                                                        </g>
                                                        <g id="Group_610" data-name="Group 610" transform="translate(35.896 112.825)">
                                                            <path id="Path_743" data-name="Path 743"
                                                                  d="M166.2,204.281l18.553-19.965.605-15.931,36.1-10.285-1.412,23.393-50.618,30.451Z"
                                                                  transform="translate(-166.2 -158.1)" fill="url(#linear-gradient-5)" />
                                                        </g>
                                                        <path id="Path_744" data-name="Path 744"
                                                              d="M408.427,123.417h0a98.411,98.411,0,0,0-104.26-11.293L165.22,169.8a4.519,4.519,0,0,0-2.42,3.832,92.565,92.565,0,0,0,4.638,28.636c4.033,11.7,10.89,21.376,13.915,25.41a3.939,3.939,0,0,0,4.235,1.613L333,196.217a98.307,98.307,0,0,0,75.422-72.8Z"
                                                              transform="translate(-133.76 -102.147)" fill="url(#linear-gradient-6)" />
                                                        <path id="Path_745" data-name="Path 745"
                                                              d="M272.44,189.165a98.272,98.272,0,0,0,54.046-65.742h0A98.238,98.238,0,0,0,243.4,104.668h0a169.235,169.235,0,0,0,29.04,84.5Z"
                                                              transform="translate(-51.819 -102.153)" fill="url(#linear-gradient-7)" />
                                                        <path id="Path_746" data-name="Path 746"
                                                              d="M250.257,102.8c-2.218.4-4.638.807-6.857,1.21h0a170.959,170.959,0,0,0,28.838,84.5c2.017-1.008,4.235-2.017,6.252-3.227A167.022,167.022,0,0,1,250.257,102.8Z"
                                                              transform="translate(-51.819 -101.495)" fill="#597dfc" />
                                                        <circle id="Ellipse_29" data-name="Ellipse 29" cx="18.15" cy="18.15" r="18.15"
                                                                transform="translate(155.483 37.806)" fill="url(#linear-gradient-8)" />
                                                        <circle id="Ellipse_30" data-name="Ellipse 30" cx="13.511" cy="13.511" r="13.511"
                                                                transform="translate(160.121 42.444)" fill="url(#linear-gradient-9)" />
                                                        <path id="Path_747" data-name="Path 747"
                                                              d="M244.327,147.188a11.925,11.925,0,1,0-15.125-7.462A11.788,11.788,0,0,0,244.327,147.188Z"
                                                              transform="translate(-66.862 -79.939)" fill="url(#linear-gradient-10)" />
                                                        <path id="Path_748" data-name="Path 748"
                                                              d="M236.375,137.775c1.21,1.815,4.437.2,5.647-3.227.807-2.622-.2-5.848-1.613-5.848-2.017,0-1.613,2.42-2.622,4.638C236.778,135.758,235.367,136.363,236.375,137.775Z"
                                                              transform="translate(-59.314 -75.164)" fill="#fff" />
                                                        <path id="Path_749" data-name="Path 749"
                                                              d="M236.641,132.948a1.4,1.4,0,1,1,.807-1.815A1.226,1.226,0,0,1,236.641,132.948Z"
                                                              transform="translate(-60.589 -73.564)" fill="#fff" />
                                                        <path id="Path_750" data-name="Path 750"
                                                              d="M158.417,169.52l-2.017-5.848L209.841,142.9l3.428,10.285Z"
                                                              transform="translate(-140.267 -60.728)" fill="url(#linear-gradient-11)" />
                                                        <path id="Path_751" data-name="Path 751"
                                                              d="M235.711,195.576l15.326-3.428a286.22,286.22,0,0,1-26.62-78.447L210.1,119.548A281.779,281.779,0,0,0,235.711,195.576Z"
                                                              transform="translate(-85.673 -90.414)" fill="url(#linear-gradient-12)" />
                                                        <g id="Group_611" data-name="Group 611" transform="translate(29.04 0.115)">
                                                            <path id="Path_752" data-name="Path 752"
                                                                  d="M164.212,177.814,307.8,121.953c29.443-13.31,62.112-15.528,88.329-6.857a98.262,98.262,0,0,0-91.959-2.823L165.22,169.747a4.519,4.519,0,0,0-2.42,3.832,49.432,49.432,0,0,0,.2,5.042A1.852,1.852,0,0,1,164.212,177.814Z"
                                                                  transform="translate(-162.8 -102.21)" fill="url(#linear-gradient-13)" />
                                                            <path id="Path_753" data-name="Path 753"
                                                                  d="M395.96,119.8c-15.327,22.99-42.551,41.341-73.809,48.8L172.113,205.709a4.44,4.44,0,0,1-1.613.2c1.21,1.613,2.218,3.025,2.823,4.033a3.939,3.939,0,0,0,4.235,1.613l147.416-33.073A98.967,98.967,0,0,0,395.96,119.8Z"
                                                                  transform="translate(-154.972 -84.328)" fill="url(#linear-gradient-14)" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_614" data-name="Group 614" transform="translate(143.681 173.325)">
                                                    <path id="Path_754" data-name="Path 754"
                                                          d="M206.3,169.986c.2,2.622,2.622,8.873,4.235,10.285,1.613,1.21,26.216-4.638,29.241-10.89,1.613-3.428-14.52-6.252-21.376-7.058C214.772,161.718,206.1,167.364,206.3,169.986Z"
                                                          transform="translate(-161.427 -41.218)" fill="url(#linear-gradient-15)" />
                                                    <path id="Path_755" data-name="Path 755"
                                                          d="M188.993,107.4s-9.277,16.738-2.42,34.888c3.63,9.478,30.451,28.233,31.863,32.468,3.63,10.487,7.865,46.786,8.873,56.668,0,0,12.906-1.008,15.931-7.865,0,0-4.235-51.626-6.05-58.483-1.613-6.252-25.41-29.241-28.636-38.316-1.21-3.227.4-20.166.4-20.166S209.16,96.3,188.993,107.4Z"
                                                          transform="translate(-184.048 -102.247)" fill="url(#linear-gradient-16)" />
                                                </g>
                                                <g id="Group_622" data-name="Group 622" transform="translate(15.513 57.707)">
                                                    <path id="Path_756" data-name="Path 756"
                                                          d="M223.1,128.738s-12.907-20.973-35.9-20.771c0,0,7.865-31.056,16.536-31.661C212.61,75.7,221.886,99.7,223.1,128.738Z"
                                                          transform="translate(-52.675 -13.014)" fill="url(#linear-gradient-17)" />
                                                    <path id="Path_757" data-name="Path 757"
                                                          d="M183.865,81.416c-5.445,8.873-6.05,11.7-5.848,12.7.4,1.008,10.89,51.223,13.31,56.668,2.218,5.445,29.241,2.622,34.485-9.277,4.235-9.478-6.857-64.936-10.486-66.347C211.9,73.35,189.713,71.938,183.865,81.416Z"
                                                          transform="translate(-62.044 -15.504)" fill="#abdcf4" />
                                                    <g id="Group_615" data-name="Group 615" transform="translate(153.854)">
                                                        <path id="Path_758" data-name="Path 758"
                                                              d="M224.811,62.832c.2-2.017.2-3.428,4.235-7.26s9.882-8.672,11.7-10.285c1.008-1.008,2.42.2,1.815,1.613-1.412,3.025-6.252,7.462-6.252,7.462s4.235,4.235,5.647,6.857c.6,1.008.807,2.622-1.412,4.84-2.42,2.218-7.462,4.437-11.092,3.63A6.6,6.6,0,0,1,224.811,62.832Z"
                                                              transform="translate(-168.314 -44.915)" fill="url(#linear-gradient-18)" />
                                                        <path id="Path_759" data-name="Path 759"
                                                              d="M234.755,49.557c-.4-.4-1.008-.6-3.025.6s-3.63,3.227-2.823,4.033,2.017.2,3.63-.807C233.949,52.582,236.167,50.969,234.755,49.557Z"
                                                              transform="translate(-164.343 -40.514)" fill="url(#linear-gradient-19)" />
                                                        <path id="Path_760" data-name="Path 760"
                                                              d="M235.555,50.557c-.4-.4-1.008-.6-3.025.6s-3.63,3.227-2.823,4.033,2.017.2,3.63-.807C234.95,53.582,236.967,51.969,235.555,50.557Z"
                                                              transform="translate(-163.53 -39.497)" fill="url(#linear-gradient-20)" />
                                                        <path id="Path_761" data-name="Path 761"
                                                              d="M236.355,51.557c-.4-.4-1.008-.6-3.025.6s-3.63,3.227-2.823,4.033,2.017.2,3.63-.807C235.75,54.582,237.767,52.969,236.355,51.557Z"
                                                              transform="translate(-162.717 -38.48)" fill="url(#linear-gradient-21)" />
                                                        <path id="Path_762" data-name="Path 762"
                                                              d="M236.652,52.857c-.4-.4-1.008-.6-2.823.6s-3.428,3.025-2.622,3.832c.807.6,1.815.2,3.428-.807C236.047,55.479,238.064,54.067,236.652,52.857Z"
                                                              transform="translate(-162.006 -37.159)" fill="url(#linear-gradient-22)" />
                                                        <path id="Path_763" data-name="Path 763"
                                                              d="M230.156,49.147c1.008-.807,5.243,3.832,8.067,6.252.2.2-.6,1.412-2.622,1.412A6.217,6.217,0,0,1,231.971,55c3.227,5.042.4,11.092-2.622,10.285a6.254,6.254,0,0,1-4.638-6.655A14.056,14.056,0,0,1,230.156,49.147Z"
                                                              transform="translate(-168.415 -40.708)" fill="url(#linear-gradient-23)" />
                                                        <path id="Path_764" data-name="Path 764"
                                                              d="M197.421,91.816s26.216-7.462,31.661-12.1c9.478-7.663,16.738-18.956,24-26.216,1.613.4,6.252,5.243,7.26,8.47-1.613,2.017-9.68,16.133-21.175,25.611-11.293,9.277-33.275,19.763-35.291,17.948C193.791,97.059,197.421,91.816,197.421,91.816Z"
                                                              transform="translate(-196.785 -36.188)" fill="url(#linear-gradient-24)" />
                                                    </g>
                                                    <path id="Path_765" data-name="Path 765"
                                                          d="M191.5,73.919s18.956,24.6,24.8,47.189c.807,3.63,1.613,7.462,2.218,11.495,1.008-26.216-11.092-60.9-15.931-60.1C196.542,73.515,191.7,73.919,191.5,73.919Z"
                                                          transform="translate(-48.303 -16.879)" fill="url(#linear-gradient-25)" />
                                                    <path id="Path_766" data-name="Path 766"
                                                          d="M194.815,76.7a52.552,52.552,0,0,1,16.738,23.393c7.663,20.973,7.663,31.056,2.017,64.331-25.611,7.058-25.611-12.7-44.769-12.3,0,0,14.721.4,17.948-25.006,2.823-21.78-3.025-28.031-2.823-35.291C184.126,89.606,187.555,83.355,194.815,76.7Z"
                                                          transform="translate(-71.381 -12.602)" fill="url(#linear-gradient-26)" />
                                                    <g id="Group_618" data-name="Group 618" transform="translate(0 73.326)">
                                                        <g id="Group_617" data-name="Group 617" transform="translate(0 53.287)">
                                                            <g id="Group_616" data-name="Group 616" transform="translate(0 4.614)">
                                                                <line id="Line_39" data-name="Line 39" y1="6.655" x2="1.21"
                                                                      transform="translate(60.515 15.149)" fill="none" stroke="#bbbaba"
                                                                      stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                                                                      stroke-width="1.013" />
                                                                <line id="Line_40" data-name="Line 40" y1="4.033" x2="5.445"
                                                                      transform="translate(43.978 7.688)" fill="none" stroke="#bbbaba"
                                                                      stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                                                                      stroke-width="1.013" />
                                                                <path id="Path_767" data-name="Path 767"
                                                                      d="M124.138,149.586l46.181,28.233A7.527,7.527,0,0,0,180.6,175.4L195.527,151l-58.886-36.1-15.125,24.4A7.588,7.588,0,0,0,124.138,149.586Z"
                                                                      transform="translate(-120.492 -104.994)" fill="url(#linear-gradient-27)" />
                                                                <rect id="Rectangle_62" data-name="Rectangle 62" width="7.663" height="69.171"
                                                                      transform="translate(11.847 16.481) rotate(-58.519)" fill="#49484b"
                                                                      opacity="0.4" />
                                                                <path id="Path_768" data-name="Path 768"
                                                                      d="M134.905,129.372l40.131,24.6a12.561,12.561,0,0,0,17.343-4.235h0a3.941,3.941,0,0,0-1.21-5.445l-55.054-33.678a3.941,3.941,0,0,0-5.445,1.21h0A13.077,13.077,0,0,0,134.905,129.372Z"
                                                                      transform="translate(-111.9 -109.988)" fill="url(#linear-gradient-28)" />
                                                                <path id="Path_769" data-name="Path 769"
                                                                      d="M151.552,120.511l9.68,5.848a1.924,1.924,0,0,1,.6,2.42l-.2.2-11.092-6.857a1.247,1.247,0,0,1-.4-1.412h0A.941.941,0,0,1,151.552,120.511Z"
                                                                      transform="translate(-90.432 -99.513)" fill="url(#linear-gradient-29)" />
                                                                <path id="Path_770" data-name="Path 770"
                                                                      d="M148.8,118.5l-9.68-5.848a1.726,1.726,0,0,0-2.42.6l-.2.2,11.092,6.857c.4.2,1.21.2,1.412-.4h0A.941.941,0,0,0,148.8,118.5Z"
                                                                      transform="translate(-104.218 -107.581)" fill="url(#linear-gradient-30)" />
                                                                <path id="Path_771" data-name="Path 771"
                                                                      d="M144.226,114.21v.4a1.628,1.628,0,0,0,.807,1.815.741.741,0,0,0,.6.2,15.914,15.914,0,0,1,6.05,2.42,21,21,0,0,1,5.042,4.437l.4.4a1.832,1.832,0,0,0,2.017,0l.4-.2a1.437,1.437,0,0,0,.6-1.21,1.92,1.92,0,0,0-.4-1.412,21.855,21.855,0,0,0-6.05-5.243A19.743,19.743,0,0,0,146.243,113a3.432,3.432,0,0,0-1.412.2A1.715,1.715,0,0,0,144.226,114.21Z"
                                                                      transform="translate(-96.417 -106.926)" fill="url(#linear-gradient-31)" />
                                                            </g>
                                                            <path id="Path_772" data-name="Path 772"
                                                                  d="M162.042,114.355l-5.848-6.655a43.186,43.186,0,0,1-2.622,3.832c-.807,1.008-2.823,1.412-3.832,2.42-2.017,2.218-4.437,6.05-4.638,7.865s6.857,7.462,8.47,6.453a25.068,25.068,0,0,0,3.025-4.437l1.412,1.412c2.017-3.025,1.815-7.058,2.017-7.663C160.025,117.582,160.832,116.372,162.042,114.355Z"
                                                                  transform="translate(-95.477 -107.7)" fill="url(#linear-gradient-32)" />
                                                        </g>
                                                        <path id="Path_773" data-name="Path 773"
                                                              d="M208.291,81.324c-3.832,1.21-33.678,15.326-38.114,20.57-5.647,6.857-13.713,20.973-21.376,34.081,1.008,4.033,9.882,8.873,12.7,6.252,3.025-6.453,18.15-27.628,20.57-30.855,2.218-3.227,28.233-11.293,30.048-13.31C219.181,90,210.913,80.518,208.291,81.324Z"
                                                              transform="translate(-91.714 -81.276)" fill="url(#linear-gradient-33)" />
                                                    </g>
                                                    <g id="Group_621" data-name="Group 621" transform="translate(87.538 22.327)">
                                                        <path id="Path_774" data-name="Path 774"
                                                              d="M186.55,72.7s-4.638,1.412-5.042,4.033c-.4,2.823,7.058,3.227,7.058,3.227Z"
                                                              transform="translate(-146.015 -38.995)" fill="url(#linear-gradient-34)" />
                                                        <g id="Group_619" data-name="Group 619" transform="translate(28.938)">
                                                            <path id="Path_775" data-name="Path 775"
                                                                  d="M186.794,97.172a10.823,10.823,0,0,0,.605-4.437c-.2-3.428.6-6.453-2.622-9.882-4.84-5.042-8.47-14.721-4.235-20.771,4.437-6.252,13.915-6.655,17.948-5.243s5.647,9.478,6.857,10.89c1.412,1.613,4.437,3.428,4.437,4.033a6.753,6.753,0,0,1-1.412,2.622c-.605.605,2.218,7.462,2.218,9.478,0,1.815-2.42,2.823-4.437,3.227-1.815.4-1.613,7.462-1.412,8.268C204.742,96.365,200.91,101.608,186.794,97.172Z"
                                                                  transform="translate(-177.819 -55.804)" fill="url(#linear-gradient-35)" />
                                                            <path id="Path_776" data-name="Path 776"
                                                                  d="M185.671,69.711c-1.008.2.4,1.412,2.017,2.017,3.63,2.017,10.083,4.235,10.083,6.857,0-2.218.4-4.84,1.613-5.042,1.815-.4,4.033-1.412,4.437-3.025-.4,2.017-6.05,2.017-11.7.807C188.7,70.72,186.881,69.51,185.671,69.711Z"
                                                                  transform="translate(-171.05 -42.057)" fill="url(#linear-gradient-36)" />
                                                            <path id="Path_777" data-name="Path 777"
                                                                  d="M187.426,82.028c-3.227-2.017-3.025-4.84-2.218-5.647,1.21-1.21,3.428-.6,5.445,1.008.4.4-.4-1.613-.605-2.017-.4-1.412.4-5.243-.4-6.453-2.017-3.428-2.42-6.453,2.017-6.655,5.848-.2,8.268-3.025,9.68-3.025,0-1.008-1.21-2.218-2.218-2.622-4.033-1.412-14.52-.807-18.956,5.445-4.235,6.05-.807,15.73,4.033,20.771,1.613,1.815,3.025,3.63,3.227,5.243C188.838,86.061,191.258,84.448,187.426,82.028Z"
                                                                  transform="translate(-178.25 -55.987)" fill="url(#linear-gradient-37)" />
                                                        </g>
                                                        <g id="Group_620" data-name="Group 620" transform="translate(50.013 40.965)">
                                                            <path id="Path_778" data-name="Path 778"
                                                                  d="M196.363,83.157l-1.21-6.857-3.832.4L188.7,79.728l3.832,4.84A5.775,5.775,0,0,1,196.363,83.157Z"
                                                                  transform="translate(-188.7 -76.3)" fill="#ff5f74" />
                                                        </g>
                                                        <path id="Path_779" data-name="Path 779"
                                                              d="M190.1,79.653s5.243,2.017,7.26,6.05c0,0,0-5.445-1.815-9.277a23.336,23.336,0,0,0-2.218-3.227C192.923,73.2,192.52,78.04,190.1,79.653Z"
                                                              transform="translate(-137.264 -38.487)" fill="url(#linear-gradient-38)" />
                                                        <path id="Path_780" data-name="Path 780"
                                                              d="M164.707,87.368s7.462-8.268,15.73-8.268,33.476,13.511,37.51,2.622c0,0,.6-2.823,3.832-1.21,0,0-.4,10.083-10.083,12.1s-27.023-3.025-32.266-1.21a43.962,43.962,0,0,0-8.672,4.033l-6.857-1.21Z"
                                                              transform="translate(-163.9 -32.489)" fill="#ff5f74" />
                                                        <path id="Path_781" data-name="Path 781"
                                                              d="M181.67,74.7c.2,1.008,5.042,5.243,17.141,3.63a37.228,37.228,0,0,0-.2,9.277s-14.116-5.243-16.738-8.873C181.872,78.733,181.267,75.708,181.67,74.7Z"
                                                              transform="translate(-145.975 -36.962)" fill="url(#linear-gradient-39)" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_453" data-name="Group 453" transform="translate(126.5 311.4)">
                                            <g id="Group_451" data-name="Group 451" transform="translate(0 -19.986)" opacity="0.15">
                                                <path id="Path_430" data-name="Path 430"
                                                      d="M26.282,142.291a30.408,30.408,0,0,1,9.96-49.376c3.956-1.625,8.194-2.472,12.079-4.238C61.884,82.39,66.9,66.214,74.528,53.358c11.231-18.648,30.939-32.352,52.554-34.471s44.431,8.265,55.592,26.913c6,10.031,8.688,22.039,15.964,31.222a38.9,38.9,0,0,0,20.2,13.35c6.993,1.907,14.339,1.766,21.4,3.32,10.878,2.4,21.191,9.395,25.5,19.708s1.271,23.734-8.194,29.668Z"
                                                      transform="translate(-14 42.718)" fill="#c6d3fd" />
                                            </g>
                                            <g id="Group_452" data-name="Group 452" transform="translate(113 -110.783)" opacity="0.34">
                                                <path id="Path_431" data-name="Path 431"
                                                      d="M52.966,107.644a11.535,11.535,0,0,1,3.809-18.727c1.481-.635,3.1-.917,4.585-1.622,5.149-2.4,7.053-8.535,9.945-13.4C75.537,66.8,83.014,61.62,91.2,60.809s16.858,3.174,21.09,10.227c2.292,3.809,3.315,8.358,6.066,11.85A14.882,14.882,0,0,0,126,87.965c2.645.705,5.431.67,8.111,1.27,4.126.917,8.041,3.562,9.663,7.477s.494,9.028-3.1,11.25Z"
                                                      transform="translate(75 -9.478)" fill="#c6d3fd" />
                                            </g>
                                            <g id="Group_624" data-name="Group 624" transform="translate(775 40.217)" opacity="0.34">
                                                <path id="Path_431-2" data-name="Path 431"
                                                      d="M54.185,125.048a15.812,15.812,0,0,1,5.221-25.67c2.03-.87,4.254-1.257,6.285-2.224,7.058-3.287,9.669-11.7,13.633-18.371,5.8-9.717,16.05-16.824,27.266-17.935S129.7,65.2,135.5,74.867c3.142,5.221,4.544,11.457,8.315,16.243A20.4,20.4,0,0,0,154.3,98.072c3.626.967,7.445.919,11.119,1.74,5.656,1.257,11.022,4.883,13.246,10.249s.677,12.376-4.254,15.422Z"
                                                      transform="translate(0 0)" fill="#c6d3fd" />
                                            </g>
                                        </g>
                                        <g id="Group_469" data-name="Group 469" transform="translate(798.5 286.4)">
                                            <ellipse id="Ellipse_14" data-name="Ellipse 14" cx="5.7" cy="6" rx="5.7" ry="6"
                                                     transform="translate(297.9 99.6)" fill="#e1d7ff" opacity="0.34" />
                                            <circle id="Ellipse_15" data-name="Ellipse 15" cx="3" cy="3" r="3" transform="translate(283.5 97.8)"
                                                    fill="#e1d7ff" opacity="0.34" />
                                        </g>
                                        <g id="Group_470" data-name="Group 470" transform="translate(798.5 286.4)">
                                            <ellipse id="Ellipse_16" data-name="Ellipse 16" cx="5.7" cy="6" rx="5.7" ry="6"
                                                     transform="translate(52.2 97.8)" fill="#e1d7ff" opacity="0.34" />
                                            <circle id="Ellipse_17" data-name="Ellipse 17" cx="3" cy="3" r="3" transform="translate(61.4 85.8)"
                                                    fill="#e1d7ff" opacity="0.34" />
                                            <circle id="Ellipse_18" data-name="Ellipse 18" cx="3" cy="3" r="3" transform="translate(68 97.2)"
                                                    fill="#e1d7ff" opacity="0.34" />
                                        </g>
                                        <g id="Group_479" data-name="Group 479" transform="translate(798.5 286.4)">
                                            <g id="Group_476" data-name="Group 476">
                                                <g id="Group_475" data-name="Group 475">
                                                    <path id="Path_476" data-name="Path 476" d="M215.9,39c-1.2,1.1-2.5,2.1-3.7,3.2" fill="none"
                                                          stroke="#b095fa" stroke-miterlimit="10" stroke-width="0.816" />
                                                    <path id="Path_477" data-name="Path 477"
                                                          d="M204.3,48.1a143.531,143.531,0,0,1-47,21.6c-3.2.8-6.5,1.5-9.7.9s-6.3-2.9-6.9-6.1c-.9-4.4,2.9-8.3,6.8-10.4,3.2-1.8,7-3,10.3-1.5,2.9,1.3,4.6,4.5,5.1,7.6,1,5.8-1.3,11.8-4.4,16.8s-7.1,9.4-10.3,14.5a49.949,49.949,0,0,0-5.4,40.6c2.8,9.3,8.7,17.8,16.8,23"
                                                          fill="none" stroke="#b095fa" stroke-miterlimit="10" stroke-width="0.816"
                                                          stroke-dasharray="9.856 9.856" />
                                                    <path id="Path_478" data-name="Path 478" d="M163.8,157.3a36.11,36.11,0,0,0,4.6,1.7" fill="none"
                                                          stroke="#b095fa" stroke-miterlimit="10" stroke-width="0.816" />
                                                </g>
                                            </g>
                                            <g id="Group_478" data-name="Group 478">
                                                <path id="Path_479" data-name="Path 479"
                                                      d="M217.2,30.9s-7.6-2.8-17.5,1.1c0,0,8.4,7.6,11.7,9.3C211.4,41.3,220.7,32.3,217.2,30.9Z"
                                                      fill="#422b7f" />
                                                <path id="Path_480" data-name="Path 480"
                                                      d="M217.8,33a35.83,35.83,0,0,1-4.5.9A18.82,18.82,0,0,1,202,31.2a20.944,20.944,0,0,0-2.3.8s8.4,7.6,11.7,9.3C211.4,41.3,216.7,36.1,217.8,33Z"
                                                      fill="#422b7f" />
                                                <path id="Path_481" data-name="Path 481"
                                                      d="M225.1,45.2s-2.4,7.8-11.5,13.3c0,0-1-11.3-.3-14.9C213.3,43.6,226.1,41.6,225.1,45.2Z"
                                                      fill="#422b7f" />
                                                <path id="Path_482" data-name="Path 482"
                                                      d="M220.8,43.1a49.284,49.284,0,0,0-7.4.5c-.7,3.6.3,14.9.3,14.9a5.582,5.582,0,0,0,.8-.5C216.8,53.1,217.6,47.5,220.8,43.1Z"
                                                      fill="#422b7f" />
                                                <path id="Path_483" data-name="Path 483"
                                                      d="M232.5,41.2c-10.4,5.9-20.6,7.3-22.9,3.3s4.3-12.1,14.6-17.9c10.4-5.9,22.9-3.3,22.9-3.3S242.9,35.4,232.5,41.2Z"
                                                      fill="#2fc1e1" />
                                                <path id="Path_484" data-name="Path 484"
                                                      d="M246.5,24.9A46.879,46.879,0,0,1,231,37a38.289,38.289,0,0,1-10.6,3.2c-3,.4-6.5.6-9.1-1.1a4.349,4.349,0,0,1-.7-.6c-1.4,2.4-1.9,4.5-1,6.1,2.3,4,12.6,2.6,22.9-3.3C240.6,36.7,244.9,28.4,246.5,24.9Z"
                                                      fill="#2fc1e1" opacity="0.38" />
                                                <path id="Path_485" data-name="Path 485"
                                                      d="M243,31.3a39.233,39.233,0,0,0,4.2-8,38.911,38.911,0,0,0-9.1-.5A21.315,21.315,0,0,1,243,31.3Z"
                                                      fill="#422b7f" />
                                                <g id="Group_477" data-name="Group 477">
                                                    <path id="Path_486" data-name="Path 486"
                                                          d="M235,36.1a4.992,4.992,0,1,1,1.9-6.8A5.054,5.054,0,0,1,235,36.1Z" fill="#422b7f" />
                                                    <circle id="Ellipse_23" data-name="Ellipse 23" cx="3.8" cy="3.8" r="3.8"
                                                            transform="translate(228.7 27.9)" fill="#fff" />
                                                </g>
                                                <path id="Path_487" data-name="Path 487" d="M215.5,39.7l1.2,2.1-12.2,5.7Z" fill="#422b7f" />
                                            </g>
                                        </g>
                                        <g id="Group_482" data-name="Group 482" transform="translate(798.5 286.4)">
                                            <path id="Path_494" data-name="Path 494"
                                                  d="M91.6,112.2l-2.7-1.8-3,1.1.9-3-2-2.5,3.2-.1,1.7-2.7,1.1,3,3.1.8-2.5,2Z" fill="#b095fa" />
                                            <path id="Path_495" data-name="Path 495"
                                                  d="M183.3,48.6l-2.5-.1-1.4,2-.7-2.4-2.3-.8,2-1.4.1-2.5,1.9,1.6,2.4-.7-.9,2.3Z" fill="#b095fa" />
                                            <path id="Path_496" data-name="Path 496"
                                                  d="M256.9,114l-2.3-2.2-3.2.6,1.4-2.9-1.5-2.8,3.1.5,2.2-2.4.6,3.2,2.9,1.3-2.8,1.5Z" fill="#b095fa" />
                                            <path id="Path_497" data-name="Path 497"
                                                  d="M211.3,17.8,209,15.6l-3.1.6,1.4-2.9-1.6-2.8,3.2.5L211,8.6l.6,3.2,2.9,1.3-2.8,1.5Z"
                                                  fill="#b095fa" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="text-center mt-4 mb-5">
                            <h2 class="mb-3">Subscription Success!</h2>
                            <p> You Have Successfully purchased {{package.name}} package.</p>
<!--                            <a href="#" class="button semi-button-info"></a>-->
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
    const DashboardLayout = () => import("../../layouts/DashboardLayout.vue");
    import client from "../../app/api/Client";

    export default {
        components: {
            DashboardLayout,
        },
        data() {
            return {
                package: null,
            }
        },
        computed: {
            slug() {
                return this.$route.params.slug;
            }
        },
        methods: {
            async getCurrentPackage() {
                try {
                    let {data: {data}} = await client().get(`/subscription/packages/${this.slug}`);
                    this.package = data;
                } catch (e) {
                }
            }
        },
        async created() {
            await this.getCurrentPackage();
        }
    }
</script>
